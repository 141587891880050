<template>
  <Toast/>
		<!-- <Toolbar class="col-12" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
			<template #start>
				<Button :label="$t('button.new')" icon="pi pi-file" @click="onNew($event)"/>
				<i class="mr-2" />
				<Button v-if="receipt.id" :label="$t('button.edit')" icon="pi pi-pencil" @click="onEdit($event)"/>
				<i class="mr-2" />
				<Button v-if="receipt.id" :label="$t('button.preview')" icon="pi pi-eye" @click="onPreview()"/>
			</template>
		</Toolbar> -->
		<div id="receipt_form" class="p-inputtext-sm md:col-6">
			<div class="card p-fluid">
				<h5 style="font-weight:bold;">{{ $t('receipt.sender')}}</h5>
				<div class="grid field">
					<label for="sendername" class="mb-2 md:col-3 md:mb-0">{{$t('issue.senderName')}}</label>
					<label class="md:col-8 fieldValue">{{issue.senderName}}</label>
				</div>
      </div>
    </div>  
  <Listbox :options="entities" optionLabel="name">
    <template #option="slotProps">
      <div :class="'conversation-item' + slotProps.index%2">
        <div class="info">
          <span class="date">{{formatter.formatDate(slotProps.option.createdDate, pattern)}}</span>
          <span class="sender">{{slotProps.option.createdUser}}</span>
        </div>
        <span class="message">{{slotProps.option.message}}</span>
      </div>
    </template>
  </Listbox>
</template>

<script>
  import IssueService from '@/service/IssueService'
  import formatter from '@/utils/formatter';
  import { Consts } from '@/utils/consts';
  export default {
    name: "Conversation",
    components: {},
    props: {
      entities : {
        type: Array,
        default() {
          return []
        }
      },
    },
    data() {
      console.log(this.entities);
      return {
        issue: {},
        items: this.entities,
        selectedItem: null,
        formatter,
        pattern: Consts.FORMAT_DATE_TIME_US,

      }
    },
    mounted() {
      let { id } = this.$route.params;
      if(id) {
        this._init(this.$route.params.id);
      }
    },
    methods: {
      async _init(issueId) {
        let data = await IssueService.get(issueId);
        this.issue = data;
        console.log(data);
      }
    }
  };
</script>
<style type = "text/css">
.item-style {
  background-color: #e9ecef;
}
.conversation-item0 {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 5px;
  /*background-color: #e9ecef;*/
}
.conversation-item1 {

}
.info {
  padding-bottom: 2px;
}
.date {
  padding-right: 10px;
  font-style: italic;
}
.sender {
  font-weight: bold
}
</style>
